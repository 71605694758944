/*
 * @Author: LiuTao
 * @Date: 2021-11-08 14:10:54
 * @LastEditTime: 2022-07-01 16:01:33
 * @LastEditors: steven 1105624290@qq.com
 * @Description: 
 * @FilePath: /ytjj-mobile/src/api/login/index.js
 */

import request from '@/utils/request.js';

export function frontLoginApi(data) {
    return request({
        url: '/mobileLogin',
        method: 'post', // 请求方式     
        data: data, // post参数
        headers: {
            isToken: false
        }
    })
}
// 获取登录信息
export function mobileGetInfoApi() {
    return request({
        url: '/mobileGetInfo',
        method: 'get',    
    })
}
//查看教案列表
export function WeekListApi(id,schoolYearId,startWeek,endWeek) {
    return request({
        url: `/park/classes/mobile/home/week/${id}/${schoolYearId}/${startWeek}/${endWeek}`,
        method: 'get',
    })
}

//查看教案详情 /template/lessonPlanner/mobile/getLessonPlannerDetail/55/56/999
export function getLessonPlannerDetailApi(classesId,schoolYearId,id) {
    return request({
        url: `/template/lessonPlanner/mobile/getLessonPlannerDetail/${classesId}/${schoolYearId}/${id}`,
        method: 'get',
    })
}

//编辑教案详情
export function editLessonPlannerApi(data) {
    return request({
        url: '/template/lessonPlanner/mobile/editLessonPlanner',
        method: 'post',
        data:data
    })
}

//编辑教案添加标识
export function lockLessonPlannerApi(id) {
    return request({
        url: `/template/lessonPlanner/lock/${id}`,
        method: 'get',
    })
}
// 反思维度
export function reflectApi(id) {
    return request({
        url: `/ytjj/template/ReflectionAfterClass/getInfo/${id}`,
        method: 'get',
    })
}
//获取班级学生和老师接口
export function parkClassesHomeTotalApi(id, schoolYearId) { // id 是班级的编号,schoolYearId 是班级所属的学年信息的学年编号
    return request({
        url: `park/student/getClassStudent/${id}/${schoolYearId}`,
        method: 'get', // 请求方式        
    })
}
// 学科字典接口
export function getIntroducedListApi(language) {
    return request({
        url: `/template/introduced/list/${language}/1`,
        method: 'get',
    })
}
//查询活动区域字典表
export function queryActivityPlaceApi(language){
    return request({
        url:"ytjj/template/ActivityPlace/list/dict",
        method: 'get',
        params: language
    })
}
// 查询活动类型字典表
export function queryActivityTypeApi(language){
    return request({
        url:"ytjj/template/ActivityType/list/dict",
        method: 'get',
        params: language
    })
}
// 查询成人参与字典表
export function queryActivityAdultParticipationApi(language){
    return request({
        url:"ytjj/template/ActivityAdultParticipation/list/dict",
        method: 'get',
        params: language
    })
}
// 查询知识点推荐字典
export function queryContentThatCanBeIntroducedTpApi(params){
    return request({
      url:"ytjj/template/ContentThatCanBeIntroducedTp/list/dict",
      method: 'get',
      params: params
    })
  }

// 教师角色
export function teacherRoleApi(languageId) {
    return request({
        url: 'template/activityTeacherRole/list',
        method: 'get',
        params:languageId
    })
}

// 退出登录
export function logout() {
    return request({
        url: '/logout',
        method: 'get', // 请求方式
    })
}