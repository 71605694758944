<!--
 * @Author: your name
 * @Date: 2021-11-15 15:46:44
 * @LastEditTime: 2022-06-01 09:54:21
 * @LastEditors: steven 1105624290@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/components/chooseClass/index.vue
-->
<template>
    <div class="class_dialog">
        <van-dialog v-model="show" title="选择班级" :showConfirmButton="false" class="class_list">
            <van-icon @click="close" name="cross" class="cancal" />
            <!-- <div class="school_year" v-if="Array.isArray(sendClass) && !!sendClass.length">
                {{!!sendClass[0].schoolLoginClassesGradeIsTeacherDetailResponseVos.length&&sendClass[0].schoolLoginClassesGradeIsTeacherDetailResponseVos[0].schoolYear.name}} 学年
            </div> -->
            <div class="school_year">
                {{stuYear}} 学年
            </div>
            <div class="class_item" v-show="item.schoolLoginClassesGradeIsTeacherDetailResponseVos.length>0 && item.isTeacher"
                 v-for="(item,index) in cloneclassData" :key="index">
                <div class="grade"
                    :class="item.grade.name=='中班'? 'center' : item.grade.name=='大班'?'big':item.grade.name=='大大班'?'more_big':item.grade.name=='托班'?'toban':''">
                    {{item.grade.name}}</div>
                <div class="flex_img">
                    <div class="class_pic" v-for="(classItem,classIndex) in 
                    item.schoolLoginClassesGradeIsTeacherDetailResponseVos"
                    :key="classIndex"
                    v-show="classItem.isTeacher === '1'"
                    @click="goLessPlan(classItem)"
                    >
                    <!-- v-show="classItem.isTeacher === '1'" -->
                        <img :src="classItem.schoolYear.imageUrl + '?x-oss-process=image/resize,h_150,w_150'" alt="" class="pic">
                        <div class="text">{{classItem.schoolYear.classesName}}</div>
                    </div>
                </div>
            </div>
        </van-dialog>
        <van-dialog v-model="noClass" :showConfirmButton="false" class="noclass_dialog">
            <img src="@/assets/img/Layer 22@2x.png" alt="" class="no_class">
            <div class="text first">当前学年没有班级</div>
            <div class="text">请联系管理员配置班级信息</div>
            <van-button class="btn" @click="closeNoClass">关闭</van-button>
        </van-dialog>
    </div>
</template>
<script>
    export default{
        props:["sendClass"],
        data() {
            return{
                show:false,
                noClass:false,
                cloneclassData:[],
                schoolYearName:"",
                stuYear:""
            }
        },
        methods:{
            close(){
                this.show = false;
            },
            closeNoClass(){
                this.noClass = false;
            },
            goLessPlan(item){
                console.log(item)
                this.$storeLocal.set("classInfo",item);
                this.$store.commit("setWeekVal","2");
                this.$router.push({
                name:"list",
                params:{ id:item.schoolYear.classId,schoolYearId:item.schoolYear.id}
                })
                this.show = false;
            }
        },
        watch:{
            show(val){
                if(val){
                    this.$storeLocal.set("sendClass",this.sendClass);
                    console.log(this.$storeLocal.get('sendClass'))
                    this.cloneclassData = this._.cloneDeep(this.sendClass)
                    console.log("这是深拷贝接受过来的数据",this.cloneclassData)
                    this.cloneclassData.forEach(item => {
                        // item.schoolLoginClassesGradeIsTeacherDetailResponseVos.every(item1 => {
                        //     if(item1.isTeacher==='1'){
                        //         item.isTeacher = true;
                        //         return
                        //     }
                        // })
                        for(const item1 of item.schoolLoginClassesGradeIsTeacherDetailResponseVos){
                            if(item1.isTeacher==='1'){
                                item.isTeacher = true;
                                this.stuYear = item1.schoolYear.name;
                                return
                            }
                        }
                    })
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
    .class_dialog{
        .class_list{
            max-height: 82%;
            // overflow-y: scroll;
            overflow: auto;
            top: 50%;
            width: 690px;

            /deep/.van-dialog__header{
                font-size: 34px;
                padding-top: 26px;
            }
            .cancal{
                position: absolute;
                right: 20px;
                top: 20px;
            }
            .school_year{
                position: absolute;
                right: 40px;
                top: 90px;
                font-size: 28px;
            }
            /deep/.van-dialog__content{
                padding-bottom: 40px;
                box-sizing: border-box;
            }
            .class_item{
                padding-left: 50px;
                padding-right: 50px;
                font-size: 26px;
                color: #fff;
                margin-top: 58px;
                .grade{
                    width: 105px;
                    height: 47px;
                    border-radius: 40px 40px;
                    background-color: #5ABAB5;
                    text-align: center;
                    line-height: 47px;
                }
                .center{
                    background-color: #FFC102;
                }
                .big{
                    background-color: #DA573A;
                }
                .more_big{
                    background-color: #aaa5ce;
                }
                .toban{
                    background-color: #a4c5dd;
                }

                .flex_img{
                    display: flex;
                    flex-flow: wrap;
                    // justify-content: space-between;
                    margin-top: 26px;
                    // padding-left: 25px;
                    // padding-right: 25px;
                    // border: 1px solid red;

                    .class_pic{
                        // margin-right: 64px;
                        // width: 120px;
                        width: 33%;
                        // border: 1px solid red;
                        text-align: center;
                        margin-bottom: 20px;

                        .pic{
                            border-radius: 50%;
                            width: 120px;
                            height: 120px;
                            object-fit:fill
                            
                        }
                        
                        .text{
                            color: #4C4C4C;
                            font-size: 26px;
                            text-align: center;
                            // margin-top: 12px;
                        }
                    }
                }

            }
        }


        .noclass_dialog{
            width: 690px;
            height: 504px;
            padding: 30px 40px;
            box-sizing: border-box;

            /deep/.van-dialog__content{
                text-align: center;

                .no_class{
                    width: 193px;
                    height: 171px;
                    margin: 0 auto;
                }
                
                .first{
                    margin-top: 45px;
                }

                .text{
                    color: #4C4C4C;
                    font-size: 28px;
                }

                .btn{
                    width: 609px;
                    height: 86px;
                    background-color: #4FB9B8;
                    color: #fff;
                    margin-top: 46px;
                }
            }
        }
    }
</style>

