<!--
 * @Author: your name
 * @Date: 2021-11-21 16:19:05
 * @LastEditTime: 2021-12-17 15:56:41
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/components/header/index.vue
-->
<template>
    <div class="img_header">
        <div class="left_img" @click="chooseClass">
            <img :src="classInfo.schoolYear.imageUrl" alt="" class="class_img">
        </div>
        <div class="right_info" @click="chooseClass">
            <div class="class_name">
                <span>{{classInfo.schoolYear.classesName}}</span>
                <van-icon v-show="showSelect" class="arrow-down" name="arrow-down" />
            </div>
            <div class="school_name">
                {{schoolName}}
            </div>
            <div class="year_name">
                {{classInfo.schoolYear.name}}学年
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props:["showSelect"],
        data() {
            return {
                schoolName:"",
                classInfo:{
                    schoolYear:{}
                }
            }
        },
        methods:{
            chooseClass(){
                this.$emit("showClass",true)
            }
        },
        mounted(){
            console.log("存储的学校的数据",this.$storeLocal.get("schoolData"));
            console.log("存储的班级的数据",this.$storeLocal.get("classInfo"));
            this.schoolName = this.$storeLocal.get("schoolData")[0].nameCn;
            this.classInfo = this.$storeLocal.get("classInfo");
        },
        activated(){      
            this.$storeLocal.set("classInfo",this.classInfo);
        }
    }
</script>
<style lang="scss" scoped>
    .img_header{
        // margin-left: 43px;
        // margin-top: 83px;
        display: flex;

        .left_img{
            width: 121px;
            height: 121px;
            .class_img{
                width: 121px;
                height: 121px;
                border-radius: 50% 50%;
            }
        }

        .right_info{
            font-size: 22px;
            color: #DAEFED;
            margin-left: 36px;
            .class_name{
                font-size: 36px;
                color: #FFFFFF;
                font-weight: bold;

                .arrow-down{
                    margin-left: 25px;
                    font-size: 24px;
                    font-weight: bold;
                }
            }
            // .school_name{
            //     margin-top: 10px;
            // }
            // .year_name{
            //     margin-top: 10px;
            // }
        }
        
    }
</style>
