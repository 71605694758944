<!--
 * @Author: your name
 * @Date: 2021-11-19 11:16:16
 * @LastEditTime: 2022-07-23 10:56:41
 * @LastEditors: steven 1105624290@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/views/lesson_plan/list/index.vue
-->
<template>
    <div class="lesson_plan_list">
            <div class="header">
                <div class="top">
                    <headPortrait :showSelect="true" @showClass="showClass" />
                    <div class="week">
                        <div :class="weekVal===item.val?'text_color':''" v-for="(item,index) in weekData" :key="index" @click="chooseWeekplan(item)">{{item.data}}</div>
                    </div>
                    <div class="more_point">
                        <!-- <van-icon @click="showLogoOut" class="icon_top" name="ellipsis" /> -->
                        <img @click="showLogoOut" class="icon_top" src="@/assets/pd/choose.png" alt="">
                    </div>
                </div>
            </div>
            <div class="content" v-if="!hasData">
                <div class="content_body">
                    <div class="plan_list" :class="$moment().format('YYYY-MM-DD') === item.date?'a':''" v-show="item.classesDetailLessonPlannerDateVosCurrent.length>0" v-for="(item,index) in teacherPlanData" :key="index">
                        <div class="time" v-if="item.classesDetailLessonPlannerDateVosCurrent.length>0">
                            <div class="time_text" :class="$moment().format('YYYY-MM-DD') === item.date?'is_today':''">
                                <div class="date">{{item.date1}}</div>
                                <div class="week">{{'周'+'日一二三四五六'.charAt(new Date(item.date).getDay())}}</div>
                                <div class="today" v-if="$moment().format('YYYY-MM-DD') === item.date">今天</div>
                            </div>
                            <div class="line"></div>
                        </div>
                        <div class="right_item">
                            <div class="right_content" :class="$moment().format('YYYY-MM-DD') === item.date?'today_bg':''"
                            v-for="(item1,index1) in item.classesDetailLessonPlannerDateVosCurrent" :key="index1"
                            @click="goDetails(item1.lessonPlanner.id)"
                            >
                                <div class="time_reflect">
                                    <div v-for="(item2,index2) in item1.lessonPlannerDateDetailVos" :key="index2">
                                        <div v-for="(item3,index3) in item2.lessonPlannerDateDetails" :key="index3">
                                            {{item3.startTime}} - {{item3.endTime}}
                                        </div>
                                    </div>
                                    <div class="reflect">
                                        <van-icon class="icon_checked" :class="item1.lessonPlanner.reflection.replace(/<[^>]+>/g, '').trim().length >=10?'finfish':''" name="checked" />
                                        <span>反思</span>
                                    </div>
                                </div>
                                <div class="active">
                                    <!-- <div>活动：</div> -->
                                    <div class="active_text" v-html="item1.lessonPlanner.name"></div>
                                </div>
                                <div class="theme">{{item1.inquiryQuestionName}}</div>
                            </div>
                        </div>
                    </div>
                    <van-loading class="loading" v-if="loading" />
                </div>
            </div>
            <div class="content aaa" v-else>
                <div class="noData">
                    <div class="noData_text">
                        <img src="@/assets/img/Layer 45.png" alt="">
                    </div>
                    <div class="text_no">
                        <div class="noData_word">当前教案空空如也</div>
                        <div class="noData_word">快去创建吧！</div>
                    </div>
                    
                </div>
            </div>
            <ChooseClass :sendClass="sendClass" ref="selectClass"></ChooseClass>
    </div>
</template>
<script>
    import headPortrait from "@/components/header/index"
    import ChooseClass from "@/components/chooseClass/index"
    import { getCurrentWeek, getLastWeek, getNextWeek } from "@/utils/utils";
    import { WeekListApi } from "@/api/login/index.js";
    export default {
        components:{
            headPortrait,
            ChooseClass
        },
        data() {
            return {
                weekData:[
                    {
                        data:"上周",
                        val:"1"
                    },
                    {
                        data:"本周",
                        val:"2"
                    },
                    {
                        data:"下周",
                        val:"3"
                    },
                ],
                // weekVal:this.$store.state.weekVal,
                planParameter:{
                    id:this.$route.params.id,
                    schoolYearId:this.$route.params.schoolYearId,
                    startWeek:getCurrentWeek(0)[0],
                    endWeek:getCurrentWeek(0)[1]
                },
                teacherPlanData:[],
                sendClass:this.$storeLocal.get("sendClass"),
                hasData:false,
                loading:false
            }
        },
        methods:{
            //点击右上角三个点
            showLogoOut(){
                this.$store.commit("setIsShowLogo",true);
            },
            //跳转详情
            goDetails(id){
                this.$router.push({
                    name:"details",
                    params:{ classesId:this.$route.params.id,schoolYearId:this.$route.params.schoolYearId,id:id}
                })
            },
            // 上周本周下周切换
            chooseWeekplan(item){
                console.log(item)
                // this.weekVal = item.val;
                this.$store.commit("setWeekVal",item.val)
                if(this.weekVal==='1'){
                    this.planParameter.startWeek = getLastWeek(1)[0];
                    this.planParameter.endWeek = getLastWeek(1)[1];
                }else if(this.weekVal==='2'){
                    this.planParameter.startWeek = getCurrentWeek(0)[0];
                    this.planParameter.endWeek = getCurrentWeek(0)[1];
                }else if(this.weekVal==='3'){
                    this.planParameter.startWeek = getNextWeek(1)[0];
                    this.planParameter.endWeek = getNextWeek(1)[1];
                }
            },
            //获取教案列表数据
            queryWeekList(){
                this.loading = true;
                WeekListApi(this.planParameter.id,this.planParameter.schoolYearId,this.planParameter.startWeek,this.planParameter.endWeek).then(res => {
                    console.log('这是本周教案数据',res.data)
                    this.teacherPlanData = this._.cloneDeep(res.data.classesDetailLessonPlannerVos)
                    this.loading = false;
                    this.teacherPlanData.forEach(item =>{
                        item.date1 = this.$moment(item.date).format('MM月DD日');
                        item.classesDetailLessonPlannerDateVosCurrent.forEach(item1 => {
                            if(item1.lessonPlanner.name.indexOf("<p><br></p>") != -1){
                                item1.lessonPlanner.name = item1.lessonPlanner.name.replace(/<br>/gi, "");
                            }
                        })
                    })
                    this.hasData = this.teacherPlanData.every(item => {
                        return item.classesDetailLessonPlannerDateVosCurrent.length === 0
                    })
                    console.log(this.hasData)
                    setTimeout(() => {
                        let ele = document.getElementsByClassName("a")[0];
                        if(ele){
                            ele.scrollIntoView({
                                behavior: "instant",
                                block: "start",
                                inline: "start",
                            });
                        }
                        // else{
                        //     let aa = document.getElementsByClassName("plan_list")[0];
                        //     aa.scrollIntoView({
                        //         behavior: "instant",
                        //         block: "start",
                        //         inline: "start",
                        //     });
                        // }
                    }, 1);
                })
            },
            // 出现选择班级弹框
            showClass(item){
                console.log(item)
                if(item){
                    this.$refs.selectClass.show = true
                }
            }
        },
        computed:{
            weekVal(){
                return this.$store.state.weekVal
            }
        },
        watch:{
            weekVal:{
                immediate:true,
                handler(val){
                    console.log("监听变化的值",val);
                    if(val==='1'){
                        this.planParameter.startWeek = getLastWeek(1)[0];
                        this.planParameter.endWeek = getLastWeek(1)[1];
                    }else if(val==='2'){
                        this.planParameter.startWeek = getCurrentWeek(0)[0];
                        this.planParameter.endWeek = getCurrentWeek(0)[1];
                    }else if(val==='3'){
                        this.planParameter.startWeek = getNextWeek(1)[0];
                        this.planParameter.endWeek = getNextWeek(1)[1];
                    }
                    this.queryWeekList();
                }
            }
        },
        mounted(){
            // console.log("本周开始结束日期",getCurrentWeek(0));
            // console.log("上周开始结束日期",getLastWeek(1));
            // console.log("下周开始结束日期",getNextWeek(1));
            // this.queryWeekList();
            // console.log(this.$store.state.weekVal)
            // console.log(this.$moment("2021-11-22").format('MM月DD'))
            // console.log("存储的各个班级的数据",this.$storeLocal.get("sendClass"))
        },
        activated(){
            this.queryWeekList();
            this.$refs.selectClass.show = false;
            setTimeout(() => {
                let ele = document.getElementsByClassName("a")[0];
                if(ele){
                    ele.scrollIntoView({
                        behavior: "instant",
                        block: "start",
                        inline: "start",
                    });
                }
            }, 1);
        },
        created(){
            
        },
    }
</script>
<style lang="scss" scoped>
    .lesson_plan_list{
        height: 100%;
        width: 100%;
        /deep/p{
            margin: 0 !important;
        }

        .header{
            position: relative;
            height: 265px;

            .top{
                position: absolute;
                left: 0;
                top: 0;
                height: 265px;
                width: 100%;
                background-color: #4DB3B3;
                padding: 33px 0 0 43px;
                box-sizing: border-box;

                .week{
                    display: flex;
                    justify-content: space-between;
                    font-size: 32px;             
                    color: #A9E0DD;
                    padding: 0 52px;
                    margin-top: 55px;
                }
                .text_color{
                    color: #FFFFFF;
                    font-weight: bold;
                }
                .more_point{
                    position: absolute;
                    right: 43px;
                    top: 30px;
                    .icon_top{
                        // font-size: 40px;
                        // font-weight: bold;
                        // color: #FFFFFF;
                        width: 45px;
                        height: 36px;
                    }
                }
            }
        }

        .content{
            width: 100%;
            height: calc(100% - 265px);
            font-size: 28px;
            background-color: #f3f2f29c;
            padding-top: 30px;
            box-sizing: border-box;
            .content_body{
                overflow: hidden;
                overflow-y: scroll;
                height: 100%;
            }

            .plan_list{
                // padding-left: 43px;
                // padding-top: 25px;
                // padding-bottom: 25px;
                // margin: 35px 0;
                margin-bottom: 35px;
                display: flex;

                .time{
                    // color: #4FB9B8;
                    font-size: 28px;
                    font-weight: bold;
                    width: 168px;
                    text-align: center;
                    color: #000000;

                    .time_text{
                        height: 140px;
                        // border: 1px solid red;


                        .today{
                            width: 105px;
                            height: 47px;
                            line-height: 47px;
                            background-color: #DAEFED;
                            border-radius: 80px 80px;
                            margin: 0 auto;
                            margin-top: 5px;
                            font-weight: 400;
                            background-color: #edad0d;
                            color: white;
                        }
                    }

                    .is_today{
                        color: #edad0d;
                    }

                    .line{
                        height: calc(100% - 140px);
                        width: 2px;
                        border-left: 1px solid #eaeaec;
                        margin: 0 auto;
                    }
                }

                .right_content{
                    width: 562px;
                    height: 304px;
                    background-color: #eaeaec;
                    border-radius: 30px;
                    padding:38px 30px 0 38px;
                    box-sizing: border-box;
                    overflow: hidden;
                    overflow-y: scroll;
                    // margin-bottom: 17px;
                    margin-top: 17px;
                    background-color:white;

                    .time_reflect{
                        display: flex;
                        justify-content: space-between;

                        .clock{
                            font-size: 28px;
                        }

                        .reflect{
                            font-size: 24px;
                            color: #000000;
                            display: flex;
                            align-items: center;

                            .icon_checked{
                                color: #CECECE;
                                font-size: 34px;
                                margin-right: 15px;
                            }
                            .finfish{
                                color: #4DB3B3;
                            }
                        }
                    }

                    .active{
                        // display: flex;
                        // margin-top: 44px;
                        // color: #929293;
                        font-size: 32px;
                        margin-top: 35px;

                        .active_text{
                            height: 85px;
                            line-height: 1.4;
                            overflow: hidden;
                            display: -webkit-box;
                            text-overflow:ellipsis;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            font-weight: bold;
                            // border: 1px solid red;
                        }
                        /deep/p{
                            overflow: hidden;
                            display: -webkit-box;
                            text-overflow:ellipsis;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }

                        /deep/span{
                            color: #929293 !important;
                        }
                    }
                    .theme{
                        // font-size: 32px;
                        margin-top: 20px;
                        display: flex;
                        // margin-top: 44px;
                        color: #929293;
                        // height: 75px;
                        // border: 1px solid red;
                        overflow: hidden;
                        display: -webkit-box;
                        text-overflow:ellipsis;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }

                .right_content:nth-child(1){
                    margin-top: 0;
                }

                .today_bg{
                    background-color: #f9eed2;
                }
            }

            .loading{
                position: absolute;
                left: 50%;
                top: 50%;
            }
        }
        
        .aaa{
            .noData{
                margin-top: 179px;

                .noData_text{
                    width: 193px;
                    height: 142px;
                    margin: 0 auto;

                    img{
                        width: 193px;
                        height: 142px;
                    }
                }

                .text_no{
                    margin-top: 51px;
                    .noData_word{
                        font-size: 28px;
                        color: #848484;
                        text-align: center;
                    }
                }

                
            }
        }
    }
</style>